.customer {
	background:#ffffff;
	margin:2px;
	padding:5px;
	cursor:pointer;
}

.customer:hover {
	background:rgba(255,255,255,0.7);
}

.customer span {
	float:right;
}

.edited {
	outline:solid 2px #ff0000;
}

.details {
	padding:10px;
	margin:5px;
	background:#ffffff;
	border:solid 1px #000;
	border-radius:2px;
	//box-shadow:1px 1px 5px 0px rgba(0,0,0,1);
}

.iserp {
	color:rgba(0,0,0,0.4);
}

.noerp {
	font-weight:700;
}

.projectBox {
	padding:5px;
	margin-top:15px;
	box-shadow:1px 1px 5px 0px rgba(0,0,0,0.4);
}

.projectName {
	font-weight:700;
}
