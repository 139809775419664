
.pipeline {
	display:flex;
	margin-bottom:50px;
}

.header {
	font-weight:700;
	text-align:center;
	border:solid 1px #000;
	border-radius:2px;
	margin:5px;
	background:rgba(100,100,200,1);
	color:#ffffff;
}

.pipelinecol {
	flex: 1;
}

.total {
	text-align:center;
}

.bottombtn {
	background:rgba(255,255,255,0.2);
	position:fixed;
	bottom:0;
	left:0;
	right:0;
	padding:5px;

}

.pre {
	border-bottom:solid 1px rgba(0,0,0,0.2);
	white-space:break-spaces;
}

.pre2 {
	padding-left:15px;
	white-space:break-spaces;
}


@media screen and (max-width: 600px) {
	.pipeline {
		flex-direction: column;
	}
}

.dead {
	background:rgba(255,0,0,0.2);
}
