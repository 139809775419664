@import "./Colors.module.scss";
.container {
	background:#ffffff;
	border:solid 1px #000;
	border-radius:2px;
	padding:5px;
	margin: 5px;
}

.container:hover {
	box-shadow:1px 1px 4px 1px rgba(0,0,0,0.5);
	cursor:pointer;
}

.header {
	font-weight:700;
}

.details {
}

.amount {
}

.stars {
}

.time {
}




.desc {
	border-left:solid 1px #000;
	margin-left:7px !important;
	padding-left:20px;
}

.mh > h5 {
	width:100%;
}

.DONE { color:rgba(46,216,46,1); }
.TODO { color:rgba(255,216,46,1); }
.DONT { color:rgba(216,46,46,1); }
.ALARM { color:rgba(46,46,46,1); }

.status-DONT {
	text-decoration:line-through;

}

.edittask {
	margin-top:10px;
	margin-bottom:10px;
	background:rgba(0,0,0,0.1);
	padding:10px;
	border-radius:2px;
	box-shadow:0px 0px 5px 0px rgba(0,0,0,0.5);
}

.taskrow:hover {
	cursor:pointer;
	background:rgba(0,0,0,0.1);
}

.dpinput {
	border:unset;
	cursor:pointer;
}

.late {
	border-left:solid 15px rgba(216,46,46,1);
}

.today {
	border-left:solid 15px rgba(255,216,46,1);

}

.future {
	border-left:solid 15px rgba(46,216,255,1);

}
